import angular from 'angular';
import app from '../../app';

app.directive('labelCard', [
  '$rootScope',
  'LabelTemplatesService',
  'CompanyService',
  'PpeService',
  function ($rootScope, LabelTemplatesService, CompanyService, PpeService) {
    return {
      restrict: 'E',
      templateUrl: require('./labelCard.html'),
      replace: true,
      scope: {
        label: '=',
        labelTemplateClass: '=',
        labelTemplate: '=',
        borderStyle: '=',
        showManu: '=',
        showStorageCode: '=',
        showDisposalCode: '=',
        customPrintableFields: '=',
        customFields: '=',
        additionalInformationHtml: '=',
        sdsLocationHtml: '=',
        qrcode: '=',
        njLabel: '=',
        state: '=',
        njContainerDisabled: '=',
        printQrCodeOnly: '=',
        showPpe: '=',
        printRequiredInfoOnly: '=',
        showNfpa: '='
      },
      link: function (scope, element) {
        scope.qrV = 4;
        scope.qrE = 'M';
        scope.qrcodeSize = 100;
        scope.generateViewSdsUrl = $rootScope.generateUrl.bind(null, 'viewSds');
        scope.getAttrLangName = CompanyService.getAttrLangName;
        scope.PpeService = PpeService;
        scope.nfpaSize = 35;

        scope.label.customPrintableFields = (scope.customPrintableFields || []).map(makeLabelField);
        scope.label.customFields = (scope.customFields || []).map(makeLabelField);
        if (scope.state && scope.state.fontSize) {
          element[0].style.fontSize = scope.state.fontSize;
        }
        LabelTemplatesService.registerNewLabel(scope, element[0]);

        function makeLabelField(el) {
          const ret = angular.copy(el);
          for (let i = 0; i < scope.label.attr.length; i++) {
            const attr = scope.label.attr[i];
            const arr = attr.split('=');
            let header, title;
            if (arr.length === 2) {
              header = arr[0];
              title = arr[1];
            } else header = ret;

            if (header === el.header) {
              ret.title = title;
              break;
            }
          }

          return ret;
        }
      }
    };
  }
]);
