import app from '../../app';

app.directive('companySelector', [
  '$q',
  function ($q) {
    return {
      restrict: 'E',
      templateUrl: require('./companySelector.html'),
      scope: {
        dontWrapSelectTag: '=',
        companies: '=',
        onCompanySelected: '=',
        allCompaniesOption: '=',
        allCompaniesText: '@',
        applyClasses: '@',
        noMainCompanyChange: '=',
        selectCompanyOption: '=',
      },
      controllerAs: 'vm',
      controller: function (
        $rootScope,
        $scope,
        $translate,
        CompanyFactory,
        CompanyService,
        SpinnerService
      ) {
        const vm = this;
        const getCompanies = SpinnerService.wrap(function () {
          if (vm.companies) return waitForCompaniesFromOutside();

          vm.companies = [];
          let r = null;
          if (CompanyFactory.allCompanies) {
            if ($scope.allCompaniesOption) addAllCompaniesOption();
            if ($scope.addSelectCompanyOption) addSelectCompanyOption();

            CompanyFactory.allCompanies.forEach(company => vm.companies.push(company));
            addSelectCompanyOption();
            r = $q.resolve(vm.companies);
          } else {
            r = CompanyFactory.GetCompanies()
              .$promise.then(companies => {
                if ($scope.allCompaniesOption) addAllCompaniesOption();
                if ($scope.addSelectCompanyOption) addSelectCompanyOption();

                companies.forEach(company => vm.companies.push(company));

                return companies;
              })
              .then(allCompanies => {
                addSelectCompanyOption();
                CompanyFactory.allCompanies = allCompanies;
                return CompanyFactory.allCompanies;
              });
          }
          return r;
        });

        vm.companies =
          $scope.companies != null && Array.isArray($scope.companies) ? $scope.companies : null;
        vm.onSelect = SpinnerService.wrap(async function () {
          const selectedCompany = await selectCompany();

          return $scope.onCompanySelected && $scope.onCompanySelected(selectedCompany);
        });
        vm.hasAccess = false;
        vm.applyClasses = $scope.applyClasses || '';

        init();

        async function init() {
          vm.hasAccess = await checkAccess();

          if (!vm.hasAccess) return;
          if (vm.companies == null && isParentCompanyAdmin()) {
            vm.companies = $scope.companies = [
              $rootScope.currentCompany,
              ...$rootScope.currentCompany.childSdsCompanies
            ];
          }

          await getCompanies();
          vm.onSelect();
          $scope.$apply();
        }

        async function selectCompany() {
          if (!vm.selectedCompany && $rootScope.sitename && !$scope.selectCompanyOption) {
            vm.selectedCompany = vm.companies.find(
              company => $rootScope.sitename === company.name.toLowerCase()
            );
          }
          if (!vm.selectedCompany) vm.selectedCompany = vm.companies[0];
          if (
            vm.selectedCompany &&
            vm.selectedCompany.id &&
            $rootScope.checkIfUserIs('admin') &&
            !$scope.noMainCompanyChange
          ) {
            $rootScope.onChangeMainCompany(vm.selectedCompany.name);
            await CompanyService.getCurrentCompanyPromise(false).$promise;
          }

          return vm.selectedCompany;
        }

        function addAllCompaniesOption() {
          vm.companies.push({
            name: $translate.instant($scope.allCompaniesText || 'COMMON.LABELS.ALL_COMPANIES'),
            id: null
          });

          return vm.companies;
        }
        function addSelectCompanyOption() {
          vm.companies.unshift({
            id: null,
            name: $translate.instant('COMMON.LABELS.SELECT_COMPANY')
          });

          return vm.companies;
        }
        function waitForCompaniesFromOutside() {
          return $q(resolve => {
            const unbind = $scope.$watch('companies', function () {
              if ($scope.companies.length) {
                vm.companies = $scope.companies;

                resolve(vm.companies);
                unbind();
              }
            });

            $scope.$apply();
          });
        }

        async function checkAccess() {
          await CompanyService.getCurrentCompanyPromise(false, true).$promise;

          return $rootScope.checkIfUserIs('admin') || isParentCompanyAdmin();
        }

        function isParentCompanyAdmin() {
          return !!(
            $rootScope.checkIfUserIs('teamAdmin') &&
            $rootScope.currentCompany.childSdsCompanies.length
          );
        }
      }
    };
  }
]);
